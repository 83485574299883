import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  LocationsSorted,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Locations"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <LocationsSorted
                locations={fishermanBusiness.locations.map((location) => {
                  const link1 = {
                      "Better Bagels Seaport":
                        "https://www.ezcater.com/store/caterer/pvt/119909ed-1d42-4199-89c4-3180a534df9c?c=better-bagels-1",
                      "Better Bagels Post Office Square":
                        "https://www.ezcater.com/store/caterer/pvt/119909ed-1d42-4199-89c4-3180a534df9c?c=better-bagels-3",
                    },
                    link2 = {
                      "Better Bagels Seaport":
                        "https://www.order.store/store/better-bagels/Wuv42TFkR0yyeytQyFjOWg",
                      "Better Bagels Post Office Square":
                        "https://www.toasttab.com/better-bagels-boston-211-congress-st/v3/",
                    },
                    link3 = {
                      "Better Bagels Seaport":
                        "https://www.toasttab.com/better-bagels/v3#!/",
                    };
                  return {
                    ...location,
                    actions: [
                      { url: link1[location.name], label: "Order Catering" },
                      { url: link2[location.name], label: "Order Delivery" },
                      { url: link3[location.name], label: "Order Pickup" },
                    ],
                  };
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Locations" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
